function getFileNameFromContentDispositionHeader (header: string) {
  const regex = new RegExp(/filename="*(.*?)(?:"|;)/g)
  const results = regex.exec(header)
  if (!results) {
    return 'undefined'
  }
  return results[1]
}

function downloadDocument (file: any, headers: any, documentName?: string) {
  let fileName = documentName
  if (!fileName) {
    fileName = getFileNameFromContentDispositionHeader(
      headers['content-disposition']
    )
  }
  const fileURL = window.URL.createObjectURL(
    new Blob([file], { type: 'application/octet-stream;' })
  )
  const fileLink = document.createElement('a')

  fileLink.href = fileURL
  fileLink.setAttribute('download', fileName)
  document.body.appendChild(fileLink)

  fileLink.click()
}

export { downloadDocument, getFileNameFromContentDispositionHeader }
