





















































































































import Component, { mixins } from 'vue-class-component'
import { State } from '@/mixins/state'

import { SubscriptionApi, DeclarationApi, SignSubscriptionCommand, GenerateDeclarationPdfQuery, DownloadDeclarationPdfQuery } from '@/api'

import Content from '@/components/Content.vue'
import Toolbar from '@/components/Layout/Toolbar.vue'
import FormInput from '@/components/Form/Input.vue'
import FormButton from '@/components/Form/Button.vue'
import { User } from '@/types'

import { downloadDocument } from '@/util/download'
import { Watch } from 'vue-property-decorator'

interface InputField<T> {
  name: string;
  type: string;
  value: T;
  required: boolean;
  errors: string[];
}

type Error = 'NotFound' | 'InternalError'

@Component({
  components: {
    Content,
    Toolbar,
    FormInput,
    FormButton
  }
})
export default class Employer extends mixins(State) {
  hasDirtyInput = false

  api = new SubscriptionApi()
  declarationApi = new DeclarationApi()

  get currentUser (): User {
    return this.$store.state.currentUser
  }

  public errorMsg: Error | null = null

  subscriptionSigned: InputField<boolean | null> = {
    name: 'signSubscription',
    required: true,
    type: 'checkbox',
    value: null,
    errors: []
  }

  @Watch('subscriptionSigned.value')
  methodName (newsubscriptionSigned: boolean | null) {
    if (newsubscriptionSigned) {
      this.hasDirtyInput = true
    } else {
      this.hasDirtyInput = false
    }
  }

  public async signSubscription () {
    if (!this.currentUser.subscription) {
      this.errorMsg = 'NotFound'
      return
    }

    this.setState(this.states.LOADING)
    try {
      this.clearErrors()
      const signCommand: SignSubscriptionCommand = {
        subscriptionId: this.currentUser.subscription.subscriptionId || 0,
        acceptTermsConditions: this.subscriptionSigned.value
      }

      await this.declarationApi.declarationSignSubscription(signCommand)
    } catch (error) {
      if (error.response.data.status === 404) {
        this.setState(this.states.ERROR)
        this.errorMsg = 'NotFound'
      } else if (error.response.status === 500) {
        this.setState(this.states.ERROR)
        this.errorMsg = 'InternalError'
      } else {
        this.setError(error.response.data.errors)
      }
    } finally {
      if (!this.currentStateIs(this.states.ERROR)) {
        this.setState(this.states.LOADED)
        this.setState(this.states.COMPLETE)
      }
    }
  }

  // TODO Client side validation.
  public setError (errors: []) {
    Object.entries(errors).forEach(([errorKey]) => {
      if (errorKey.toLowerCase() === 'acceptTermsConditions'.toLowerCase()) {
        this.subscriptionSigned.errors.push('Het veld ondertekenen is verplicht.')
      }
    })
  }

  public clearErrors () {
    this.subscriptionSigned.errors = []
  }

  async generateDeclarationPdf () {
    try {
      if (this.currentUser.subscriptionDetails?.sportLocationNumber && this.currentUser.subscription?.subscriptionId) {
        const generateDeclarationPdfQuery: GenerateDeclarationPdfQuery = {
          subscriptionId: this.currentUser.subscription.subscriptionId
        }

        const { data, headers } = await this.declarationApi.declarationGenerateDeclarationPdf(
          generateDeclarationPdfQuery,
          {
            responseType: 'arraybuffer'
          }
        )
        downloadDocument(data, headers)
      }
    } catch {
      this.errorMsg = 'NotFound'
    }
  }

  async downloadDeclarationPdf () {
    try {
      if (this.currentUser.subscriptionDetails?.sportLocationNumber && this.currentUser.subscription?.subscriptionId) {
        const downloadDeclarationPdfQuery: DownloadDeclarationPdfQuery = {
          subscriptionId: this.currentUser.subscription.subscriptionId
        }

        const { data, headers } = await this.declarationApi.declarationDownloadDeclarationPdf(
          downloadDeclarationPdfQuery,
          {
            responseType: 'arraybuffer'
          }
        )
        downloadDocument(data, headers)
      }
    } catch {
      this.errorMsg = 'NotFound'
    }
  }

  async downloadAao () {
    if (this.currentUser.registration) {
      const res = await this.api.subscriptionDownloadAaoPdfByRegistration(
        this.currentUser.registration.registrationId,
        {
          responseType: 'arraybuffer'
        }
      )

      downloadDocument(res.data, res.headers)
    } else if (this.currentUser.subscription) {
      const res = await this.api.subscriptionDownloadAaoPdfBySubscription(
        this.currentUser.subscription.subscriptionId,
        {
          responseType: 'arraybuffer'
        }
      )

      downloadDocument(res.data, res.headers)
    }
  }
}
